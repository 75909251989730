import { scrollToFinanceSchema } from './ScrollToFinanceSchema';
import { ScrollToFinanceEvent } from './ScrollToFinanceEvent';

type Props = {
  vehicleSource: string;
  vehiclePosition?: number | null;
  listingId: string | null;
};

export const scrollToFinanceToEvent = ({
  vehicleSource,
  vehiclePosition,
  listingId,
}: Props): ScrollToFinanceEvent => {
  const event: ScrollToFinanceEvent = {
    schema: scrollToFinanceSchema.ref,
    data: {
      vehicle_source: vehicleSource,
      vehicle_position: vehiclePosition,
      listing_id: listingId,
    },
  };

  return event;
};
