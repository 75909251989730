import { AA_DEALER_LIST } from '@whitelabel/Constants/AaDealerList';
import { SearchResults } from '@core/Entities/Search/SearchResults.entity';
import { mapImages } from '../../Vehicle/MapImages';
import { Tenant } from '@whitelabel/Entities/Tenants.entity';

export const mappedResponse = (vehiclesList: SearchResults, tenant?: Tenant): SearchResults => {
  let results = [];

  results = (vehiclesList?.content || []).map(vehicle => {
    const dealerNameList = [
      vehicle.dealer?.displayName,
      vehicle.dealer?.name,
      vehicle.dealer?.dealerGroup?.name,
    ];

    const hasAaImagery = vehicle?.media?.filters?.includes?.('aa');
    if (hasAaImagery && tenant === 'rac') vehicle.media.images = [];

    return {
      ...vehicle,
      images: mapImages(vehicle.media?.images ?? vehicle.images),
      images360: vehicle.media?.images360?.length ? mapImages(vehicle.media?.images360) : null,
      media: {
        videos: vehicle.media?.videos,
      },
      hasAaImagery: AA_DEALER_LIST.some(value => dealerNameList.includes(value)),
    };
  });

  const vehicles = {
    ...vehiclesList,
    content: results,
    number: vehiclesList?.number + 1 || 1,
  };

  return vehicles as SearchResults;
};
