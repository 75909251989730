/* eslint-disable @typescript-eslint/naming-convention */
'use server';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';

import { getMessages } from '@intl/GetMessages';

import { newsletterMessages } from 'Components/Newsletter/Newsletter.messages';

import { OPT_IN_URLS } from './OptIn.constants';

import { OptInRequestBody, OptInResponse } from './OptIn.entity';

export async function optInAction(req: OptInRequestBody): Promise<OptInResponse> {
  const { locale, email, userId, user_Id, tenant, make, model } = req;
  const strings = await getMessages(newsletterMessages, locale);
  const url = (locale && OPT_IN_URLS[locale]) ?? '';

  const payload: { [key in Locale]?: Record<string, any> } = {
    uk: {
      source: 'Footer',
      tenant,
      _userId: user_Id,
      marketing_optin_email: true,
      userId,
      make,
      model,
    },
    de: {
      id: userId,
      source: 'FOOTER',
      email: email,
      userTrackingId: null,
      tenant: 'de',
    },
  };

  const options = {
    method: 'POST',
    body: JSON.stringify({ email: email, ...payload[locale] }),
  };

  try {
    const response = await fetchJson<OptInResponse>(url, options);
    return response;
  } catch (error: any) {
    if (typeof error === 'object' && error?.status && error.status === 409) {
      return { error: strings.emailExistError };
    } else {
      return { error: strings.emailError };
    }
  }
}
