<T extends any[]>(promises: Promise<T[number]>[]) => Promise<T>;

type SimpleFetch = <T extends any[]>(
  promises: Promise<T[number]>[],
) => Promise<
  [
    T,
    {
      hasError: boolean;
      errors: any[];
      responses: PromiseSettledResult<any>[];
    },
  ]
>;

// @ts-expect-error - return type matches the type of the function
export const simpleFetch: SimpleFetch = async requests => {
  // Perform all requests asynchronously and wait for all of them to settle
  const responses = await Promise.allSettled(requests);

  // Extract the resolved values from the responses and store them in an array
  const values = responses.map(response =>
    response.status === 'fulfilled' ? response.value : null,
  );

  // Check if any of the requests resulted in a rejection (error)
  const hasError = responses.some(({ status }) => status === 'rejected');

  // Extract the reasons for the rejected requests and store them in an array
  const errors = responses
    .filter(({ status }) => status === 'rejected')
    .map(rejection => ('reason' in rejection ? rejection.reason : undefined));

  // Return the simplified fetch result as a tuple containing the values, error information, and all responses
  return [values, { hasError, errors, responses }];
};
