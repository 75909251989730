import { Locale } from '@core/Entities/Locale/Locale.entity';

import { Typography } from '@gds/Typography/Typography';

import PriceRatingGauge from 'Components/PriceRatingGauge/PriceRatingGauge';

import { priceRatingGaugeMessages } from '../PriceRatingGauge/PriceRatingGauge.messages';

import styles from './PriceRatingModal.module.css';
interface PriceRatingModalProps {
  locale: Locale;
  strings: Record<keyof typeof priceRatingGaugeMessages, string>;
}

export const PriceRatingModal = ({ locale, strings }: PriceRatingModalProps) => {
  return (
    <div className={styles.modalContent}>
      {locale !== 'fr' && (
        <Typography variant="body3" className={styles.modalIntro}>
          {strings.modalIntro}
        </Typography>
      )}

      <Typography variant="h6" className={styles.categoriesSubtitle}>
        {strings.categoriesSubtitle}
      </Typography>

      <div className={styles.categoryContainer}>
        <PriceRatingGauge locale={locale} rating="great" strings={strings} clickable={false} />
        <div className={styles.categoryText}>
          <Typography variant="body1" className={styles.subtitle}>
            {strings.greatPriceSubtitle}
          </Typography>
          <Typography variant="body3" className={styles.content}>
            {strings.greatPriceContent}
          </Typography>
        </div>
      </div>

      <div className={styles.categoryContainer}>
        <PriceRatingGauge locale={locale} rating="good" strings={strings} clickable={false} />
        <div className={styles.categoryText}>
          <Typography variant="body1" className={styles.subtitle}>
            {strings.goodPriceSubtitle}
          </Typography>
          <Typography variant="body3" className={styles.content}>
            {strings.goodPriceContent}
          </Typography>
        </div>
      </div>

      {locale !== 'fr' && (
        <div className={styles.categoryContainer}>
          <PriceRatingGauge locale={locale} rating="fair" strings={strings} clickable={false} />
          <div className={styles.categoryText}>
            <Typography variant="body1" className={styles.subtitle}>
              {strings.fairPriceSubtitle}
            </Typography>
            <Typography variant="body3" className={styles.content}>
              {strings.fairPriceContent}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
