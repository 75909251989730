/* eslint-disable @typescript-eslint/naming-convention */
'use client';
import { FC, useState } from 'react';

import { Close } from '@gds/Icons/Paths/Close';
import { Info } from '@gds/Icons/Paths/Info';
import { Typography } from '@gds/Typography/Typography';

import styles from './TooltipFinance.module.css';

interface Props {
  disclaimer: string;
  title: string;
}

const TooltipFinance: FC<Props> = ({ disclaimer, title }) => {
  const [isExpanded, setExpanded] = useState(false);

  const preventClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const toggleSection = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExpanded(event => !event);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className={styles.tooltipWrapper}>
      <div
        onClick={preventClick}
        data-finance-expanded={isExpanded}
        className={`${styles.expandable} ${isExpanded ? styles.open : ''}`}
      >
        <div onClick={preventClick} className={styles.header}>
          <Typography variant={'subheading1'}>{title}</Typography>
          <button
            className={styles.closeButton}
            data-test-id="close-button-finance-info"
            onClick={toggleSection}
          >
            <Close />
          </button>
        </div>
        <div
          onClick={preventClick}
          className={styles.fullLegalText}
          dangerouslySetInnerHTML={{ __html: disclaimer }}
        />
      </div>

      <button onClick={toggleSection} className={styles.toggle}>
        <Info />
      </button>
    </div>
  );
};
export { TooltipFinance };
