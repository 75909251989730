export const AA_DEALER_LIST = [
  'Sonny Adams Motor Company',
  'Nuneaton Car Sales',
  'Your Car Supermarket',
  'CarMotion',
  'Checked Cars',
  'JP Motor Trade',
  'Cars2 Ltd',
  'Quality Cars and Commercials',
];
