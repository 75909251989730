import { useState } from 'react';

export const useInput = (regex: RegExp, errorMessage: string) => {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');
  const [isTouched, setIsTouched] = useState(false);

  const validateInput = (inputValue: string) => {
    const isValidValue = regex.test(inputValue);
    setIsValid(isValidValue);
    setError(isValidValue ? '' : errorMessage);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    if (isTouched) {
      validateInput(inputValue);
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
    validateInput(value);
  };

  const setCustomError = (errorMessage: string) => {
    setError(errorMessage);
  };
  return { value, isValid, error, handleChange, handleBlur, setCustomError };
};
