import React, { FC } from 'react';

import { PillProps } from './Pill.types';

import styles from './Styles/Pill.module.css';

export const Pill: FC<PillProps> = React.forwardRef<HTMLElement, PillProps>(
  ({ children, leftIcon, dataTestId, Component = 'div', className = '', ...restProps }, ref) => {
    return (
      <Component
        className={`typography-subheading-3 ${styles.pill} ${className}`.trim()}
        data-test-id={dataTestId}
        ref={ref}
        {...restProps}
      >
        {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {children}
      </Component>
    );
  },
);

Pill.displayName = 'Pill';

export default Pill;
