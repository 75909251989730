import { FC, memo } from 'react';

import Grid from '@gds/Grid/Grid';
import { SuccessTickCircle } from '@gds/Icons';
import { Typography } from '@gds/Typography/Typography';

import styles from './InPageOptIn.module.css';

export const SuccessMessage: FC<{ message: string }> = memo(({ message }) => (
  <Grid.Col className={styles.signUpSuccess} width={{ desktop: 6, mobile: 12 }}>
    <SuccessTickCircle />
    <Typography variant="body1">{message}</Typography>
  </Grid.Col>
));

SuccessMessage.displayName = 'SuccessMessage';
