/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import { FC, useState } from 'react';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { getFormattedCurrency } from '@core/Utils/Intl/GetFormattedCurrency';

import styles from './ExpandableFinance.module.css';

interface Props {
  messages: {
    readMore: string;
    readLess: string;
    month: string;
  };
  financeCopy?: string | null;
  financeType?: string | null;
  financePrice?: number;
  locale: Locale;
}

const ExpandableFinance: FC<Props> = ({
  financeCopy,
  financePrice,
  financeType,
  messages,
  locale,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  if (!financeCopy) {
    return null;
  }

  if (!financePrice) {
    return <p className={styles.noFinance}>{financeCopy}</p>;
  }

  const toggleSection = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setExpanded(e => !e);
  };

  return (
    <>
      {isExpanded && (
        <div className={styles.expandable}>
          <p className={styles.financePrice}>
            {getFormattedCurrency(financePrice, locale, {
              maximumFractionDigits: 2,
            })}{' '}
            {messages.month} {financeType?.toUpperCase()}
          </p>

          <p className={styles.financeCopy} dangerouslySetInnerHTML={{ __html: financeCopy }} />
        </div>
      )}

      <Footer
        ctaText={isExpanded ? messages.readLess : messages.readMore}
        isExpanded={isExpanded}
        financeCopy={financeCopy}
        toggleSection={toggleSection}
      />
    </>
  );
};

const Footer = ({
  ctaText,
  isExpanded,
  financeCopy,
  toggleSection,
}: {
  ctaText: string;
  isExpanded: boolean;
  financeCopy?: string | null;
  toggleSection: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <div className={styles.footer}>
      {!isExpanded && (
        <p className={styles.finance} dangerouslySetInnerHTML={{ __html: '*' + financeCopy }} />
      )}

      <button
        onClick={toggleSection}
        className={`${styles.readMe} ${isExpanded ? styles.open : ''}`}
      >
        {ctaText}
      </button>
    </div>
  );
};

export { ExpandableFinance };
