import { Call } from '@gds/Icons/Paths/Call';
import { Typography } from '@gds/Typography/Typography';

export const SeeNumber = (strings: Record<string, any>) => {
  return (
    <>
      <Call />
      <Typography Component="span" variant="subheading3">
        {strings.strings.seeNumber}
      </Typography>
    </>
  );
};
