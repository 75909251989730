import { VehicleImage } from '../../Entities/Vehicle/VehicleImage.entity';
import { DEFAULT_IMG } from './Images/Images.constants';

export const isStringArray = (value: any): value is string[] => {
  return Array.isArray(value) && value.every(item => typeof item === 'string');
};

export const mapImages = (vehicleImages: VehicleImage[] | []): VehicleImage[] => {
  if (vehicleImages?.length === 0) {
    return [
      {
        url: DEFAULT_IMG,
        isRelative: false,
      },
    ];
  }

  const imageObj = vehicleImages.map(image => {
    let url = image?.url;

    return {
      url,
      isRelative: !!image?.isRelative,
    };
  });

  return imageObj;
};
