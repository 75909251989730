import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { vehicleToListingEvent } from '@tracking/Schemas/Listing/VehicleToListingEvent';
import { scrollToFinanceToEvent } from '@tracking/Schemas/ScrollToFinance/ScrollToFinanceToEvent';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';

interface Props {
  userId: string | null | undefined;
  formState: LeadFormState;
  listingId: string | null;
  source?: string;
  vehicle: Vehicle | null;
  pageType: string;
  index?: number | null;
}

export function generateScrollToFinanceTrackingData({
  userId,
  formState,
  listingId,
  vehicle,
  pageType,
  index,
}: Props) {
  const userData = {
    userId,
    values: { ...formState },
  };

  const scrollToFinanceObj = {
    vehicleSource: pageType,
    vehiclePosition: index,
    listingId,
  };

  // event actions
  const scrollToFinanceEvent = scrollToFinanceToEvent(scrollToFinanceObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const listingEvent = vehicleToListingEvent(vehicle!);

  const contextStart = [listingEvent, userEvent];

  return { event: scrollToFinanceEvent, context: contextStart };
}
