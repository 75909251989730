import React from 'react';

import { Call } from '@gds/Icons/Paths/Call';
import { Typography } from '@gds/Typography/Typography';

import styles from '../CallDealer.module.css';

interface DealerNumberProps {
  strings: Record<string, any>;
  phoneNumber: string;
}

const DealerNumber: React.FC<DealerNumberProps> = ({ strings, phoneNumber }) => {
  return (
    <>
      <Call />
      <Typography Component="span" variant="subheading3" className={styles.seeNumber}>
        {strings.seeNumber}
      </Typography>
      <Typography Component="span" variant="subheading3" className={styles.number}>
        {phoneNumber}
      </Typography>
    </>
  );
};

export default DealerNumber;
