import { Call } from '@gds/Icons/Paths/Call';
import { Typography } from '@gds/Typography/Typography';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';

import { useAtomValue } from 'jotai';

import styles from '../CallDealer.module.css';

export const CallBack = (strings: Record<string, any>) => {
  const flags = useAtomValue(flagsAtom);
  return (
    <div className={styles.wrapper}>
      <Call />
      <Typography Component="span" variant="subheading3">
        {flags.callbackCtaCopy ? strings.strings.scheduleCallBack : strings.strings.callBack}
      </Typography>
    </div>
  );
};
