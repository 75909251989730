'use client';

import { ReactElement, ReactNode } from 'react';

import Link from 'next/link';

import { HeroBannerContent } from 'Entities/HomepageContent.entity';

import styles from './HeroBanner.module.css';

const HeroBannerWrapper = ({
  heroBanner,
  children,
}: {
  heroBanner: HeroBannerContent;
  children: ReactNode;
}): ReactElement => {
  // Determine redirect URL and target based on the presence of 'props' in heroBanner
  let redirectUrl;
  let redirectURLInNewTab;

  if (heroBanner?.props) {
    const { redirect_url, target } = heroBanner.props;
    redirectUrl = redirect_url?.value;
    redirectURLInNewTab =
      redirectUrl && target?.value.length && target?.value[0].codename ? '_blank' : '_self';
  } else {
    redirectUrl = heroBanner?.redirectUrl;
    redirectURLInNewTab =
      redirectUrl && heroBanner.target?.length && heroBanner?.target[0].codename
        ? '_blank'
        : '_self';
  }

  const WrapperComponentName = redirectUrl ? Link : 'div';

  return (
    <WrapperComponentName
      href={redirectUrl || '#'}
      target={redirectURLInNewTab}
      className={styles.wrapper}
    >
      {children}
    </WrapperComponentName>
  );
};

export default HeroBannerWrapper;
