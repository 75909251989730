import { FC, memo } from 'react';

import Button from '@gds/Button/Button';
import { Input } from '@gds/Input/Input';

import { InPageOptInProps } from './InPageOptIn.entity';

import styles from './InPageOptIn.module.css';

export const OptInForm: FC<{
  email: string;
  isValid: boolean;
  error: string | null;
  isLoading: boolean;
  strings: InPageOptInProps['strings'];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}> = memo(
  ({ email, isValid, error, isLoading, strings, handleChange, handleBlur, handleSubmit }) => (
    <form onSubmit={handleSubmit} className={styles.emailInput} id="optin-Form">
      <Input
        onChange={handleChange}
        value={email}
        onBlur={handleBlur}
        {...(((email && !isValid) || error) && { error })}
        name="email"
        disabled={isLoading}
        placeholder={strings.placeHolder}
        fullWidth
        type="email"
        required
      />
      <Button type="submit" loading={isLoading}>
        {strings.signUpCta}
      </Button>
    </form>
  ),
);

OptInForm.displayName = 'OptInForm';
