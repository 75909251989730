/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import { FC, useState, useCallback } from 'react';

import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import Button from '@gds/Button/Button';
import Grid from '@gds/Grid/Grid';
import useBreakpoint from '@gds/Pagination/Hooks/UseBreakpoint.hook';
import { Typography } from '@gds/Typography/Typography';
import { useAtomValue } from 'jotai';
import Image from 'next/image';

import { optInAction } from 'Actions/OptIn/OptIn.action';
import { OPT_IN_URLS } from 'Actions/OptIn/OptIn.constants';
import { resolveAction } from 'Actions/User/Resolve.action';

import { useInput } from 'Hooks/UseInput';
import { useOptIn } from 'Hooks/UseOptIn';
import { EMAIL_VALIDATION } from 'Utils/Validations';

import { InPageOptInProps } from './InPageOptIn.entity';

import { OptInForm } from './OptInForm';
import { SuccessMessage } from './SuccessMessage';

import styles from './InPageOptIn.module.css';

export const InPageOptIn: FC<InPageOptInProps> = ({ locale, make = '', model = '', strings }) => {
  const {
    breakpoints: { isMobile },
  } = useBreakpoint();

  const {
    value: email,
    isValid,
    error,
    handleBlur,
    handleChange,
    setCustomError,
  } = useInput(EMAIL_VALIDATION, strings.emailInvalidError);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [willShowMobileEmailForm, setWillShowMobileEmailForm] = useState(false);
  const user_Id = useAtomValue(userIdAtom);
  const { optInData, setOptIn } = useOptIn();

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setIsLoading(true);
      e.preventDefault();
      const url = locale && OPT_IN_URLS[locale];

      if (!isValid || !url) return;

      let userId = null;

      try {
        if (locale === 'uk') {
          ({ user_id: userId } = await resolveAction(user_Id!, { email }, optInData));
        }
        const response = await optInAction({
          locale,
          email,
          userId,
          user_Id,
          make,
          model,
        });

        if (response.error) {
          setCustomError(response.error);
        } else {
          setOptIn(email);
          setIsSubmitted(true);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setCustomError(strings.emailError);
      }
    },
    [email, isValid, locale, make, model, optInData, setCustomError, setOptIn, user_Id],
  );

  const handleMobileButtonClick = useCallback(() => {
    setWillShowMobileEmailForm(true);
  }, []);

  if (isSubmitted) {
    return <SuccessMessage message={strings.signUpSucess} />;
  }

  return (
    <>
      {!willShowMobileEmailForm && (
        <Grid.Col className={styles.optInBannerContent} width={{ desktop: 6, mobile: 12 }}>
          {!isMobile && (
            <Image
              src="/img/Bell.svg"
              alt="Bell Icon"
              objectFit="contain"
              height={'120'}
              width={'120'}
            />
          )}
          <div className={styles.optInBannerText}>
            <Typography variant="h4">{strings.optInTitle}</Typography>
            <div className={styles.contentBody}>
              <Typography variant="body4">
                {strings.optInBodyFirst} {make} {model} {strings.optInBodySecond}
              </Typography>
              {isMobile && (
                <Button onClick={handleMobileButtonClick} className={styles.mobileButton}>
                  <Image
                    src="/img/RightChevron.svg"
                    alt="Right Chevron"
                    objectFit="contain"
                    height={'20'}
                    width={'20'}
                  />
                </Button>
              )}
            </div>
          </div>
        </Grid.Col>
      )}
      {(!isMobile || willShowMobileEmailForm) && (
        <Grid.Col width={{ desktop: 6 }}>
          <OptInForm
            email={email}
            isValid={isValid}
            error={error}
            isLoading={isLoading}
            strings={strings}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleSubmit={handleSubmit}
          />
          <Typography className={styles.privacyCaption} Component="p" variant="caption2">
            {strings.privacyPolicyFull}
          </Typography>
        </Grid.Col>
      )}
    </>
  );
};
